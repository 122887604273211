<template>
  <v-container>
    <v-row class="pt-12">
      <v-col>
        <h1 class="text-center">도움이 필요하신가요?</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 class="text-center">문제상황을 입력해 주세요!</h3>
        <h3 class="text-center">
          확인 후 빠른 시일내에 조치할 수 있도록 하겠습니다.
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" md="8">
        <tiptap-vuetify
          v-model="description"
          :extensions="extensions"
          placeholder="문제에 대한 자세한 설명을 기입해 주시기 바랍니다."
          :disableInputRules="true"
          :disablePasteRules="true"
        />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" md="8" class="text-right">
        <v-btn color="#FF4D00" dark @click="newReport">제출</v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import { newReport } from "@/api/Bugreport";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "@jwmsg/tiptap-vuetify";
export default {
  components: { TiptapVuetify },
  methods: {
    async newReport() {
      if (
        this.description ==
        `
        <p>
            <strong>제목:</strong>
        </p>
        <hr/>
        <p>
            내용:
        </p>
      `
      ) {
        return alert("내용을 입력하십시오");
      }
      const data = await newReport(this.description);
      if (data.success) {
        alert("성공적으로 제출 했습니다.");
        this.$router.push("/");
      } else {
        alert("제출에 문제가 있습니다.");
      }
    },
  },
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      description: `
        <p>
            <strong>제목:</strong>
        </p>
        <hr/>
        <p>
            내용:
        </p>
      `,
    };
  },
};
</script>

<style>
.tiptap-vuetify-editor .ProseMirror {
  min-height: 250px;
  max-height: 250px;
}
</style>
